import "./Camarena.css"
import React from "react";
import {Grid, GridRow} from 'semantic-ui-react'

class App extends React.Component {

    render() {
        return (
            <div className="ui container">
                <div className="camarena ui segment" style={{marginTop:'14px'}}>
                    <h1>Camarena's Place in Cyberspace</h1>
                    <div className="ui relaxed grid">
                        <div className="ui grid row">
                            <div className="six wide column">
                                <div style={{margin: `10px`}}>
                                    <img alt="Lulis and Herman" src="../MomAndDad.png" style={{width: '260px'}}/>
                                    <img alt="The four sons" src="./The4.png"/>
                                </div>
                            </div>
                            <div className="ten wide column">
                                <div style={{margin: `10px`}}>
                                    <img alt="The six of us at Herman's graduation" src="./The6.png" style={{width: '400px'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="camarena-dark ui segment">
                    <Grid columns={1} centered>
                        <GridRow>
                            <h3>This site is under construction. Come back soon.</h3>
                        </GridRow>
                        <GridRow>
                            <img alt="gears" src="./gear_anim.gif" className="center"/>
                        </GridRow>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default App;